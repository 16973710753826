import React from 'react'
import { Helmet } from 'react-helmet'
import ExtraTAN from '../images/extratan.png'

export default function Seo ({ title }) {
  return (
    <Helmet title={title}>
      <meta name='description' content='A repository of my projects and connections.' />
      <meta name='image' content={ExtraTAN} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <a rel="me" href="https://mastodon.matthe815.dev/@matthe815">Mastodon</a>

      <meta property='og:url' content='matthe815.dev' />
      <meta property='og:title' content='Matthe815.dev' />
      <meta property='og:description' content='A repository of my projects and connections.' />
      <meta property='og:image' content={ExtraTAN} />
``
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap" rel="stylesheet"></link>

    </Helmet>
  )
}
